<template>
  <v-layout
    :class="smUp ? 'layout_wrapper_projectimages':'layout_wrapper_projectimages_mobile'"
    row
    justify-center
    wrap>
    <v-flex
      v-if="smUp"
      xs0
      sm2
      md2>
      <v-layout
        column
        align-end>
        <v-card
          hover
          class="image_project_wrapper"
          v-for="(image, index) in images"
          :key="index"
          @click="select_image(index)"
          >
          <v-card
            v-if="image"
            :class="current_image !== index ? 'image_menu unselected_image':'image_menu selected_image'"
            :elevation="current_image !== index ?  0:10"
            :img="images[index]"
          />
        </v-card>
      </v-layout>
    </v-flex>
    <v-flex
      xs12
      sm10
      md10>
      <v-layout
        column
        :align-center="!smUp"
        :align-start="smUp">
        <v-slide-y-transition>
          <v-card
            hover
            :class="smUp ? 'current_image':'current_image_mobile'"
            :img="images[current_image]"
          />
        </v-slide-y-transition>
      </v-layout>
    </v-flex>
    <v-layout
      row
      wrap
      justify-center
      v-if="!smUp">
      <v-card
        hover
        class="image_project_wrapper"
        v-for="(image, index) in images"
        :key="index"
        @click="select_image(index)"
        >
        <v-card
          v-if="image"
          :class="current_image !== index ? 'image_menu_mobile unselected_image':'image_menu_mobile selected_image'"
          :elevation="current_image !== index ?  0:10"
          :img="images[index]"
        />
      </v-card>
    </v-layout>
  </v-layout>
</template>
<script>
export default {
  data: () => ({
    current_image: 0
  }),
  props: {
    images: Array
  },
  computed: {
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    }
  },
  methods: {
    select_image (i) {
      this.current_image = i
    }
  }
}
</script>
<style 
  scoped>
.layout_wrapper_projectimages_mobile {
  max-width: 100%;
  margin-top: 90px;
}
.layout_wrapper_projectimages {
  max-width: 90%;
}
.current_image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 50%;
  min-width: 300px;
  min-height: 400px;
  margin: 10px
}
.current_image_mobile {
  width: 100%;
  height: 100%;
  max-width: 90%;
  max-height: 50%;
  min-width: 420px;
  min-height: 400px;
  margin: 10px
}
.image_menu {
  max-height: 100%;
  max-width: 100%;
  min-height: 55px;
  min-width: 55px;
}
.image_menu_mobile {
  max-height: 100%;
  max-width: 100%;
  min-height: 35px;
  min-width: 55px;
}
.selected_image {
  border-style: solid;
  border-width: 1px;
  border-color: white
}
.unselected_image {
  opacity: .2
}
.image_project_wrapper {
  background-color: white;
  padding: -20px !important;
  margin: 10px;
  margin-top: 18px;
}
</style>